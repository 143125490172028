<template>
  <div class="contant">
    <div class="top_contant1">
      <div class="contant_picture1">
        <div>
          <img @click="operateBottomNav('主页')" class=" music same_size" src="../assets/home.png" alt="">
        </div>
        <div @click="downClickHandle()">
          <img ref="down" class="down  same_size" src="../assets/down.png" alt="">
        </div>
      </div>
    </div>
    <div class="bottom_left_contant">
      <!-- <div class="contant_picture2">
        <div>
          <img class="sure" src="../assets/sure.png" alt="">
        </div>
        <div>
          <img class="celect" src="../assets/celect.png" alt="">
        </div>
      </div> -->
    </div>
    <div class="bottom_right_contant">
      <div class="contant_picture3">
        <div>
          <img class="run"  @click="switchSpeed()" :src="switchSpeedIcon" alt="">
        </div>
      </div>
    </div>
    <div class="bottom_contant" @click="bottomContantHandle()">
      <img class="smile" src="../assets/smile.png" alt="">
      <div class="text_Tips">互动表情</div>
    </div>


    <div class="conceal_contant" ref="conceal_contant">
      <div>
        <img src="../assets/fullScreen.png" alt="" @click="operateBottomNav('全屏')">
        <img src="../assets/help-2.png" alt="" @click="operateBottomNav('帮助')">
        <img src="../assets/switchPerson.png" alt="" @click="operateBottomNav('视角切换')">
        <img src="../assets/music.png" alt=""  @click="operateBottomNav('声音')">
        <img @click="topPictureHandle()" class="top_picture" src="../assets/top.png" alt="">
      </div>
    </div>
    <div class="sector_contant" ref="sector_contant" v-show="sectorFlag">
      <div class="contant_sixsector">
        <img  @click="doActive" class="corona  animate__animated animate__pulse animate__infinite animate__slow" src="../assets/corona.png"
          alt="">
        <img class="expression1 expression animate__animated animate__bounceIn" src="../assets/expression1.png" alt="" @click="expression(1)">
        <img class="expression2 expression animate__animated animate__bounceIn" src="../assets/expression2.png" alt="" @click="expression(2)">
        <img class="expression3 expression animate__animated animate__bounceIn" src="../assets/expression3.png" alt="" @click="expression(3)">
        <img class="expression4 expression animate__animated animate__bounceIn" src="../assets/expression4.png" alt="" @click="expression(4)">
        <img class="expression5 expression animate__animated animate__bounceIn" src="../assets/expression5.png" alt="" @click="expression(5)">
        <img class="expression6 expression animate__animated animate__bounceIn" src="../assets/expression6.png" alt="" @click="expression(6)">
        <img class="expression7 expression animate__animated animate__bounceIn" src="../assets/expression7.png" alt="" @click="expression(7)">
        <img class="expression8 expression animate__animated animate__bounceIn" src="../assets/expression8.png" alt="" @click="expression(8)">
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props:{
        tem_id:Number
    },
  data() {
    return {
      sectorFlag: false,
      switchSpeedIcon: '../../icon/walk.png',
      nowType:1,
      speedType: {
        "walk":{
            src: '../../icon/walk.png'
        },
        "run":{
            src: '../../icon/run.png'
        },
      },
      isMusic: false
    }
  },
  created() {
    // window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", function () {
    //   if (window.orientation === 180 || window.orientation === 0) {
    //      console.log('竖屏！');
    //   }
    //   if (window.orientation === 90 || window.orientation === -90) {
    //     console.log('横屏！');
    //   }
    // }, false);
  },
  methods: {
    switchSpeed(){
        let nowType = this.nowType
        if(nowType == 1){
            this.switchSpeedIcon = this.speedType.run.src
            this.nowType = 2
        }
        if(nowType == 2){
            this.switchSpeedIcon = this.speedType.walk.src
            this.nowType = 1
        }
        window.game.switchPlayerSpeed();
    },
    operateBottomNav(str){
        if(str == '视角切换'){
            window.game.cameraControl.switchCameraPerson();
            this.topPictureHandle();
        }
        if(str == '全屏'){
            switchFullScreen();
            this.topPictureHandle();
        }
        if(str == '声音'){
            if(this.tem_id == 80){
                switchMusic();
  
            }
        }
        if(str == '主页'){
            toWxappHomePage();
        }
        if(str == '帮助'){
            this.$emit('showHelpTab');
            this.topPictureHandle();
        }
    },
    doActive(type){
        window.game.playerDance(1)
        this.sectorFlag = false
    },
    expression(type){
        window.game.cameraControl.emoji.showEmoji(type)
        this.sectorFlag = false
    },
    downClickHandle() {
      console.log('展开');
      this.$refs.down.style.display = "none"
      this.$refs.conceal_contant.style.height = '6.9rem'
    },
    topPictureHandle() {
      console.log('收起');
      this.$refs.down.style.display = "block"
      this.$refs.conceal_contant.style.height = '0rem'
    },
    bottomContantHandle() {

      if (this.sectorFlag == false) {
        // this.$refs.sector_contant.style.display = "block"
        this.sectorFlag = true
      } else {
        // this.$refs.sector_contant.style.display = "none"
        this.sectorFlag = false
      }
    }
  }
}
</script>
<style>
html {
  font-size: 13.3333vw;
}

body {
  font-size: 0.16rem;
}


* {
  margin: 0;
  padding: 0;
}

@media screen and (orientation: portrait) {

  /*竖屏 css*/
  html {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  body {
    background: black;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  img {
    width: .72rem;
    height: .72rem;
  }

  .top_contant1 {
    display: flex;
    justify-content: right;
    margin: .6rem .6rem 0 0;
  }

  .contant_picture1 .music {
    margin-bottom: .3rem;
  }


  .bottom_left_contant {
    position: absolute;
    left: .4rem;
    bottom: .6rem;
  }

  .contant_picture2 {
    display: flex;
  }

  .contant_picture2 .celect {
    margin-left: .4rem;
  }

  .bottom_right_contant {
    position: absolute;
    right: .6rem;
    bottom: 2.32rem;
  }

  .contant_picture3 {
    display: flex;
  }

  .contant_picture3 .run {
    margin-right: .38rem;
  }

  .bottom_contant {
    text-align: center;
    position: absolute;
    right: .45rem;
    bottom: 0.6rem;
  }

  .text_Tips {
    color: #FF8E24;
    font-size: .26rem;
  }

  .conceal_contant {
    position: absolute;
    right: .6rem;
    top: 1.7rem;
    height: 0rem;
    overflow: hidden;
    transition: all .3s linear;
  }

  .conceal_contant>div {
    display: flex;
    flex-direction: column;
  }

  .conceal_contant>div>img {
    margin-bottom: .3rem;
  }

  .sector_contant {
    display: none;
    position: absolute;
    top: 6.16rem;
    left: 2.19rem;
  }

  .contant_sixsector {
    position: relative;
  }

  .corona {
    width: 3.12rem;
    height: 3.12rem;
  }

  .expression {
    width: .72rem;
    height: .72rem;
  }

  .expression1 {
    position: absolute;
    left: -1rem;
    bottom: 1.6rem;
  }

  .expression2 {
    position: absolute;
    left: -0.74rem;
    bottom: 2.45rem;
  }

  .expression3 {
    position: absolute;
    left: -0.06rem;
    bottom: 3.15rem;
  }

  .expression4 {
    position: absolute;
    left: 0.78rem;
    bottom: 3.52rem;
  }

  .expression5 {
    position: absolute;
    left: 1.62rem;
    bottom: 3.52rem;
  }

  .expression6 {
    position: absolute;
    left: 2.46rem;
    bottom: 3.15rem;
  }

  .expression7 {
    position: absolute;
    right: -0.78rem;
    bottom: 2.45rem;
  }

  .expression8 {
    position: absolute;
    right: -1rem;
    bottom: 1.6rem;
  }
}

@media screen and (orientation: landscape) {

  /*横屏 css*/
  html {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  body {
    background: black;
    background-size: 100% 100%;
    background-attachment: fixed;
    overflow: hidden;
  }

  .same_size {
    width: .31rem;
    height: .31rem;
  }

  .music {
    position: absolute;
    top: .31rem;
    right: .495rem;
  }

  .down {
    transform: rotate(90deg);
    position: absolute;
    top: .31rem;
    right: 1.095rem;
  }

  .conceal_contant>div>img {
    width: .31rem;
    height: .31rem;
    margin-bottom: .1rem;
  }

  .conceal_contant>div {
    display: flex;
    flex-direction: column;
  }

  .conceal_contant {
    position: absolute;
    top: .31rem;
    right: 1.095rem;
    height: 0rem;
    overflow: hidden;
    transition: all .5s linear;
  }

  .contant_picture3 {
    display: flex;
    position: absolute;
    right: 0.395rem;
    bottom: .91rem;
  }

  .contant_picture3>div>img {
    width: .31rem;
    height: .31rem;
  }

  .run {
    margin-right: 0.16rem;
  }

  .bottom_contant {
    text-align: center;
    position: absolute;
    bottom: .14rem;
    right: .33rem;
  }

  .smile {
    width: .3rem;
    height: .3rem;
  }

  .text_Tips {
    color: #ffffff;
    font-size: .11rem;
  }

  .sector_contant {
    /* display: none; */
    position: absolute;
    top: 1.04rem;
    left: 3.17rem;
  }

  .corona {
    width: 1.77rem;
    height: 1.77rem;
  }

  .expression {
    width: .41rem;
    height: .41rem;
  }

  .expression1 {
    position: absolute;
    left: -0.55rem;
    bottom: 0.85rem;
  }

  .expression2 {
    position: absolute;
    left: -0.45rem;
    bottom: 1.35rem;
  }

  .expression3 {
    position: absolute;
    bottom: 1.75rem;
    right: 1.45rem;
  }

  .expression4 {
    position: absolute;
    right: .95rem;
    bottom: 2rem;
  }

  .expression5 {
    position: absolute;
    left: .95rem;
    bottom: 2rem;
  }

  .expression6 {
    position: absolute;
    bottom: 1.75rem;
    left: 1.5rem;
  }

  .expression7 {
    position: absolute;
    right: -0.45rem;
    bottom: 1.3rem;
  }

  .expression8 {
    position: absolute;
    right: -0.55rem;
    bottom: 0.85rem;
  }

  .sure,
  .celect {
    width: .305rem;
    height: .305rem;
  }

  .sure {
    position: absolute;
    bottom: .15rem;
    left: 3.565rem;
  }

  .celect {
    position: absolute;
    left: 4.27rem;
    bottom: .15rem;
  }
}
</style>